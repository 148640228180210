
<template>
    <div >
        <div class="container ">
            <div class="card mb-4">
                <h2 class="card-title m-4">Instagram</h2>
                <div class="row justify-content-center mb-4">
                    <div class="col-12 col-md-10 col-lg-8">
                        <form class="card card-sm">
                            <div class="card-body row no-gutters align-items-center">
                                <div class="col">
                                    <input v-model="query" :disabled="isLoading" class="form-control form-control-lg form-control-borderless" type="search" placeholder="Search topics or keywords">
                                </div>
                                <!--end of col-->
                                <div class="col-auto ml-2">
                                  <div class="button-container">
                                      <button class="btn btn-lg btn-success" @click="handleSearch" v-if="!isLoading">
                                        <i class="fa fa-search"></i>
                                      </button>
                                  </div>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
            </div>
            <div class="card">
                <div class="card-title m-4">
                    <div class="row">
                        <div class="col-10">
                            <h3>Historic Results</h3>
                        </div>
                        <div class="col-2">
                            <b-button variant="primary" @click="getHistoricalData"><i class="fa fa-refresh">
                            </i></b-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <b-table
                                striped
                                hover
                                :items="paginatedData"
                                :fields="fields"
                                :current-page="currentPage"
                                :per-page="perPage"
                                @update:sort-by="sortBy"
                                @update:sort-desc="sortDesc"
                            >
                                <template #cell(created_ts)="data">
                                    {{ formattedDate(data.item.created_ts) }}
                                </template>
                                <template #cell(actions)="data">
                                    <b-button @click="viewMore(data.item)" variant="primary">
                                        <i class="fa fa-eye"></i>
                                    </b-button>
                                </template>
                            </b-table>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="historicalData.length"
                                :per-page="perPage"
                                aria-controls="my-table"
                            ></b-pagination>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <UserDetailsModal
            :profiles="profiles"
            :modal-id="'user-details-modal'"
            :title="'Results for ' + selectedSearch.search"
            :search-id="selectedSearch._id"
            @hide="resetModal"
        />
    </div>
</template>


<script>
import axios from 'axios';
import {BButton, BPagination, BTable} from 'bootstrap-vue';

export default {
    name: 'InstagramIntegration',

    data: function () {
        return {
            query: '',
            isLoading: false,
            historicalData: [],
            selectedSearch: {},
            profiles: [],
            fields: [
                { key: 'search', label: 'Search', sortable: true  },
                { key: 'created_ts', label: 'Searched', sortable: true,formatter: 'formattedDate' },
                { key: 'actions', label: 'Actions', sortable: false }
            ],
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            sortDesc: false
        }
    },
    components: {
        UserDetailsModal: () => import('@/components/instagramIntegration/searchResults.vue'),
        BTable,
        BPagination,
        BButton
    },
    mounted (){
        this.getHistoricalData();
    },
    computed: {
        paginatedData() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.historicalData.slice(start, end);
        }
    },

    methods: {
        formattedDate(value) {
            const date = new Date(value);
            const formattedDate = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
            const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
            return `${formattedDate} ${formattedTime}`;
        },
        viewMore(search) {
            this.selectedSearch = search;
            axios.get(`${this.$root.serverUrl}/admin/instagram/profiles`, {
                'api-key': this.$root.apiKey,
                params: {
                    searchId: search._id
                }
            }).then(response => {
                this.profiles = response.data.data;
                console.log(this.profiles)
                this.$bvModal.show('user-details-modal');
            }).catch(error => {
                console.error('Error:', error);
            });
        },
        resetModal() {
            this.profiles = [];
            this.selectedSearch = null;
        },
        async getHistoricalData() {
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/instagram/list`);
                if(response.status === 200){
                    this.historicalData = response.data.data;
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        },

        async handleSearch(event) {
            event.preventDefault();
            this.isLoading = true;

            if (!this.query) {
                this.$bvToast.toast('Please provide a search term', {
                    title: 'Error: search term is empty',
                    variant: 'warning',
                    solid: true
                });
                this.isLoading = false;
                return;
            }

            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/instagram/search`, {
                    'api-key': this.$root.apiKey,
                    params: {
                        q: this.query
                    }
                });
                console.log(response)

                this.$bvToast.toast('Search results retrieved successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                });
            } catch (error) {
                console.error('Error:', error);
                this.$bvToast.toast('An error occurred while fetching the search results', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
            } finally {
                this.isLoading = false;
            }

    },
    }
}
</script>
<style>

.button-container {
    display: inline-block;
}

.button-loader {
    width: 60px;
    height: 48px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.loader {
    width: 16px;
    height: 16px;
    border: 2px solid #FFF;
    border-bottom-color: #343a3f;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
