import { render, staticRenderFns } from "./InstagramIntegration.vue?vue&type=template&id=45af8eb1&"
import script from "./InstagramIntegration.vue?vue&type=script&lang=js&"
export * from "./InstagramIntegration.vue?vue&type=script&lang=js&"
import style0 from "./InstagramIntegration.vue?vue&type=style&index=0&id=45af8eb1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports